<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header">
          <div class="card-title">Hisob faktura korinishi</div>
        </div>
        <div class="card-body">
          <table class="table bg-white text-center table-bordered w-90 m-auto">
            <thead>
              <tr>
                <th scope="col" colspan="14" class="text-center">
                  СЧЕТ-ФАКТУРА № {{ getActRecontDetails.reg_number }}
                </th>
              </tr>
              <tr>
                <th scope="col" colspan="14">
                  от {{ getActRecontDetails.oper_date | formatDate }}
                </th>
              </tr>
              <tr>
                <th colspan="14">
                  К товарно-отгрузочным дог №
                  {{ getActRecontDetails.contract_reg_number }} от мая 2020г
                </th>
              </tr>
              <tr>
                <th scope="col" class="px-5 text-left" colspan="5">
                  Поставщик: ООО "BTS Express Cargo Service"
                </th>
                <th scope="col" class="px-5 text-left" colspan="9">
                  <div
                    v-if="getActRecontDetails.client_description !== undefined"
                  >
                    Покупатели:
                    {{ getActRecontDetails.client_description.full_name }}
                  </div>
                </th>
              </tr>
              <tr>
                <th scope="col" class="px-5 text-left" colspan="5">
                  <div
                    v-if="
                      getActRecontDetails.company_general_info !== undefined
                    "
                  >
                    Адрес:
                    {{ getActRecontDetails.company_general_info.address }}
                  </div>
                </th>
                <th scope="col" class="px-5 text-left" colspan="9">
                  <div
                    v-if="getActRecontDetails.client_description !== undefined"
                  >
                    Адрес:
                    {{ getActRecontDetails.client_description.yur_address }}
                  </div>
                </th>
              </tr>
              <tr>
                <th scope="col" class="px-5 text-left" colspan="5">
                  <div
                    v-if="
                      getActRecontDetails.company_general_info !== undefined
                    "
                  >
                    Идентификационный номер (ИНН):
                    {{ getActRecontDetails.company_general_info.company_inn }}
                  </div>
                </th>
                <th scope="col" class="px-5 text-left" colspan="9">
                  <div
                    v-if="getActRecontDetails.client_description !== undefined"
                  >
                    Идентификационный номер (ИНН):
                    {{ getActRecontDetails.client_description.inn }}
                  </div>
                </th>
              </tr>
              <tr>
                <th scope="col" class="px-5 text-left" colspan="5">
                  <div
                    v-if="
                      getActRecontDetails.company_general_info !== undefined
                    "
                  >
                    Регистрационный код плательщика НДС:
                    {{ getActRecontDetails.company_general_info.company_nds }}
                  </div>
                </th>
                <th scope="col" class="px-5 text-left" colspan="9">
                  <div
                    v-if="getActRecontDetails.client_description !== undeifned"
                  >
                    Регистрационный код плательщика НДС:
                    {{ getActRecontDetails.client_description.code_nds }}
                  </div>
                </th>
              </tr>
              <tr>
                <th
                  rowspan="2"
                  scope="col"
                  class="text-center"
                  colspan="14"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2" colspan="2">
                  Наименование товаров (работ, услуг)
                </td>
                <td rowspan="2">Единица измерения</td>
                <td rowspan="2">Количество</td>
                <td rowspan="2" colspan="2">Стоимость поставки</td>
                <td colspan="2">Акциз</td>
                <td colspan="2">НДС</td>
                <td rowspan="2" colspan="3">Стоимость поставки с учётом НДС</td>
                <!-- <td></td> -->
              </tr>
              <tr>
                <td>Ставка</td>
                <td>Сумма</td>
                <td>Ставка</td>
                <td>Сумма</td>
              </tr>
              <tr>
                <td colspan="2">Услуга перевозки</td>
                <td>км</td>
                <td>
                  <div
                    v-if="getActRecontDetails.get_factura_amount !== undefined"
                  >
                    {{ getActRecontDetails.get_factura_amount.count }}
                  </div>
                </td>
                <td colspan="2">
                  <div
                    v-if="getActRecontDetails.get_factura_amount !== undefined"
                  >
                    {{
                      getActRecontDetails.get_factura_amount.price.toLocaleString(
                        'zh-ZH'
                      )
                    }}
                  </div>
                </td>
                <td rowspan="2" colspan="2">Без акциз</td>
                <td>{{ nds.nds }}%</td>
                <td>
                  <div
                    v-if="getActRecontDetails.get_factura_amount !== undefined"
                  >
                    {{
                      getActRecontDetails.get_factura_amount.nds.toLocaleString(
                        'zh-ZH'
                      )
                    }}
                  </div>
                </td>
                <td colspan="3">
                  <div
                    v-if="getActRecontDetails.get_factura_amount !== undefined"
                  >
                    {{
                      getActRecontDetails.get_factura_amount.price_with_nds.toLocaleString(
                        'zh-ZH'
                      )
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="text-right">
                  <b> Итого: </b>
                </td>
                <td>
                  <!-- <b> {{ nds.nds }}% </b> -->
                </td>
                <td>
                  <!-- <b
                    v-if="getActRecontDetails.get_factura_amount !== undefined"
                  >
                    {{
                      getActRecontDetails.get_factura_amount.nds.toLocaleString(
                        'zh-ZH'
                      )
                    }}
                  </b> -->
                </td>
                <td colspan="3">
                  <b
                    v-if="getActRecontDetails.get_factura_amount !== undefined"
                  >
                    {{
                      getActRecontDetails.get_factura_amount.price_with_nds.toLocaleString(
                        'zh-ZH'
                      )
                    }}
                  </b>
                </td>
              </tr>
              <tr>
                <td colspan="13" class="text-right">
                  <b>
                    всего к оплате с учетом НДС Один миллион семьсот тысяч сум
                    00 тийин
                  </b>
                </td>
              </tr>
              <tr>
                <td rowspan="2" colspan="14"></td>
              </tr>
              <tr></tr>
              <tr>
                <td colspan="5" class="text-left">
                  <div
                    v-if="
                      getActRecontDetails.company_general_info !== undeifned
                    "
                  >
                    <b>
                      Руководител:
                      {{
                        getActRecontDetails.company_general_info
                          .company_direktor
                      }}
                    </b>
                  </div>
                </td>
                <td colspan="8" class="text-left border-bottom-0">
                  <b> Получил __________________ </b>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-left">
                  <div
                    v-if="
                      getActRecontDetails.company_general_info !== undefined
                    "
                  >
                    <b>
                      Главный бухгалтер:
                      {{
                        getActRecontDetails.company_general_info
                          .company_accountant
                      }}
                    </b>
                  </div>
                </td>
                <!-- <td colspan=""></td>
          <td colspan=""></td> -->
                <td colspan="8" class="text-right border-top-0">
                  <small>
                    (подпись покупателя или уполномоченного представителя)
                  </small>
                </td>
              </tr>
              <tr>
                <td colspan="5" class="text-left">
                  <b> МП(при наличии печати) </b>
                </td>
                <!-- <td colspan=""></td>
          <td colspan=""></td> -->
                <td colspan="8"></td>
              </tr>
              <tr>
                <td colspan="14"></td>
              </tr>
              <tr>
                <td colspan="5" class="text-left border-bottom-0">
                  <b> Товар отпустил __________________ </b>
                </td>
                <!-- <td>МФО-00850 ИНН -{{ getActRecontDetails.client_inn }}</td> -->
                <td colspan="8"></td>
              </tr>
              <tr>
                <td colspan="5" class="text-right border-top-0">
                  <small>
                    (подпись покупателя или уполномоченного представителя)
                  </small>
                </td>
                <!-- <td>МФО-00850 ИНН -{{ getActRecontDetails.client_inn }}</td> -->
                <td colspan="8"></td>
              </tr>
              <tr>
                <td colspan="14"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {}
  },
  created() {
    this.$store.dispatch(
      'getClientPageFacturesDetailsTable',
      this.$route.params.id
    )
    // this.$store.dispatch('getNds')
  },
  filters: {
    formatDate: function (val) {
      if (val !== null && val !== undefined) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  computed: {
    getActRecontDetails() {
      const data = this.$store.state.clientStore.clientPageFacturesDetailsTable
      if (data[0] !== undefined) {
        return data[0]
      } else return data
    },
    nds() {
      return this.$store.state.requests.nds
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Hisob faktura korinishi' }])
  },
  methods: {
    aa() {}
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
