var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-custom gutter-b example example-compact"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table bg-white text-center table-bordered w-90 m-auto"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col","colspan":"14"}},[_vm._v(" СЧЕТ-ФАКТУРА № "+_vm._s(_vm.getActRecontDetails.reg_number)+" ")])]),_c('tr',[_c('th',{attrs:{"scope":"col","colspan":"14"}},[_vm._v(" от "+_vm._s(_vm._f("formatDate")(_vm.getActRecontDetails.oper_date))+" ")])]),_c('tr',[_c('th',{attrs:{"colspan":"14"}},[_vm._v(" К товарно-отгрузочным дог № "+_vm._s(_vm.getActRecontDetails.contract_reg_number)+" от мая 2020г ")])]),_c('tr',[_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"5"}},[_vm._v(" Поставщик: ООО \"BTS Express Cargo Service\" ")]),_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"9"}},[(_vm.getActRecontDetails.client_description !== undefined)?_c('div',[_vm._v(" Покупатели: "+_vm._s(_vm.getActRecontDetails.client_description.full_name)+" ")]):_vm._e()])]),_c('tr',[_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"5"}},[(
                    _vm.getActRecontDetails.company_general_info !== undefined
                  )?_c('div',[_vm._v(" Адрес: "+_vm._s(_vm.getActRecontDetails.company_general_info.address)+" ")]):_vm._e()]),_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"9"}},[(_vm.getActRecontDetails.client_description !== undefined)?_c('div',[_vm._v(" Адрес: "+_vm._s(_vm.getActRecontDetails.client_description.yur_address)+" ")]):_vm._e()])]),_c('tr',[_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"5"}},[(
                    _vm.getActRecontDetails.company_general_info !== undefined
                  )?_c('div',[_vm._v(" Идентификационный номер (ИНН): "+_vm._s(_vm.getActRecontDetails.company_general_info.company_inn)+" ")]):_vm._e()]),_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"9"}},[(_vm.getActRecontDetails.client_description !== undefined)?_c('div',[_vm._v(" Идентификационный номер (ИНН): "+_vm._s(_vm.getActRecontDetails.client_description.inn)+" ")]):_vm._e()])]),_c('tr',[_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"5"}},[(
                    _vm.getActRecontDetails.company_general_info !== undefined
                  )?_c('div',[_vm._v(" Регистрационный код плательщика НДС: "+_vm._s(_vm.getActRecontDetails.company_general_info.company_nds)+" ")]):_vm._e()]),_c('th',{staticClass:"px-5 text-left",attrs:{"scope":"col","colspan":"9"}},[(_vm.getActRecontDetails.client_description !== _vm.undeifned)?_c('div',[_vm._v(" Регистрационный код плательщика НДС: "+_vm._s(_vm.getActRecontDetails.client_description.code_nds)+" ")]):_vm._e()])]),_vm._m(1)]),_c('tbody',[_vm._m(2),_vm._m(3),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Услуга перевозки")]),_c('td',[_vm._v("км")]),_c('td',[(_vm.getActRecontDetails.get_factura_amount !== undefined)?_c('div',[_vm._v(" "+_vm._s(_vm.getActRecontDetails.get_factura_amount.count)+" ")]):_vm._e()]),_c('td',{attrs:{"colspan":"2"}},[(_vm.getActRecontDetails.get_factura_amount !== undefined)?_c('div',[_vm._v(" "+_vm._s(_vm.getActRecontDetails.get_factura_amount.price.toLocaleString( 'zh-ZH' ))+" ")]):_vm._e()]),_c('td',{attrs:{"rowspan":"2","colspan":"2"}},[_vm._v("Без акциз")]),_c('td',[_vm._v(_vm._s(_vm.nds.nds)+"%")]),_c('td',[(_vm.getActRecontDetails.get_factura_amount !== undefined)?_c('div',[_vm._v(" "+_vm._s(_vm.getActRecontDetails.get_factura_amount.nds.toLocaleString( 'zh-ZH' ))+" ")]):_vm._e()]),_c('td',{attrs:{"colspan":"3"}},[(_vm.getActRecontDetails.get_factura_amount !== undefined)?_c('div',[_vm._v(" "+_vm._s(_vm.getActRecontDetails.get_factura_amount.price_with_nds.toLocaleString( 'zh-ZH' ))+" ")]):_vm._e()])]),_c('tr',[_vm._m(4),_c('td'),_c('td'),_c('td',{attrs:{"colspan":"3"}},[(_vm.getActRecontDetails.get_factura_amount !== undefined)?_c('b',[_vm._v(" "+_vm._s(_vm.getActRecontDetails.get_factura_amount.price_with_nds.toLocaleString( 'zh-ZH' ))+" ")]):_vm._e()])]),_vm._m(5),_vm._m(6),_c('tr'),_c('tr',[_c('td',{staticClass:"text-left",attrs:{"colspan":"5"}},[(
                    _vm.getActRecontDetails.company_general_info !== _vm.undeifned
                  )?_c('div',[_c('b',[_vm._v(" Руководител: "+_vm._s(_vm.getActRecontDetails.company_general_info .company_direktor)+" ")])]):_vm._e()]),_vm._m(7)]),_c('tr',[_c('td',{staticClass:"text-left",attrs:{"colspan":"5"}},[(
                    _vm.getActRecontDetails.company_general_info !== undefined
                  )?_c('div',[_c('b',[_vm._v(" Главный бухгалтер: "+_vm._s(_vm.getActRecontDetails.company_general_info .company_accountant)+" ")])]):_vm._e()]),_vm._m(8)]),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_vm._v("Hisob faktura korinishi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"text-center",attrs:{"rowspan":"2","scope":"col","colspan":"14"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"rowspan":"2","colspan":"2"}},[_vm._v(" Наименование товаров (работ, услуг) ")]),_c('td',{attrs:{"rowspan":"2"}},[_vm._v("Единица измерения")]),_c('td',{attrs:{"rowspan":"2"}},[_vm._v("Количество")]),_c('td',{attrs:{"rowspan":"2","colspan":"2"}},[_vm._v("Стоимость поставки")]),_c('td',{attrs:{"colspan":"2"}},[_vm._v("Акциз")]),_c('td',{attrs:{"colspan":"2"}},[_vm._v("НДС")]),_c('td',{attrs:{"rowspan":"2","colspan":"3"}},[_vm._v("Стоимость поставки с учётом НДС")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("Ставка")]),_c('td',[_vm._v("Сумма")]),_c('td',[_vm._v("Ставка")]),_c('td',[_vm._v("Сумма")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right",attrs:{"colspan":"6"}},[_c('b',[_vm._v(" Итого: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right",attrs:{"colspan":"13"}},[_c('b',[_vm._v(" всего к оплате с учетом НДС Один миллион семьсот тысяч сум 00 тийин ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"rowspan":"2","colspan":"14"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-left border-bottom-0",attrs:{"colspan":"8"}},[_c('b',[_vm._v(" Получил __________________ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"text-right border-top-0",attrs:{"colspan":"8"}},[_c('small',[_vm._v(" (подпись покупателя или уполномоченного представителя) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-left",attrs:{"colspan":"5"}},[_c('b',[_vm._v(" МП(при наличии печати) ")])]),_c('td',{attrs:{"colspan":"8"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"14"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-left border-bottom-0",attrs:{"colspan":"5"}},[_c('b',[_vm._v(" Товар отпустил __________________ ")])]),_c('td',{attrs:{"colspan":"8"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-right border-top-0",attrs:{"colspan":"5"}},[_c('small',[_vm._v(" (подпись покупателя или уполномоченного представителя) ")])]),_c('td',{attrs:{"colspan":"8"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"14"}})])
}]

export { render, staticRenderFns }